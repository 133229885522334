import { render, staticRenderFns } from "./billRecord.vue?vue&type=template&id=4c5e8ddc&scoped=true&"
import script from "./billRecord.vue?vue&type=script&lang=js&"
export * from "./billRecord.vue?vue&type=script&lang=js&"
import style0 from "./billRecord.vue?vue&type=style&index=0&id=4c5e8ddc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c5e8ddc",
  null
  
)

export default component.exports