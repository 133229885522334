import { request } from '@/utils/request'

// 获取定制账单列表
export function getCustomizedBillList(data,pagenum,size) {
    return request({
        url: `/api/manage/customizedBill/areaList/${pagenum}/${size}`,
        method: 'post',
        data
    })
}

// 车牌查询绑定车辆信息 定制账单信息
export function getInfoByCarNumber(data) {
    return request({
        url: `/api/manage/customizedBill/getInfoByCarNumber`,
        method: 'post',
        data
    })
}

// 车架号查询绑定车辆信息 定制账单信息
export function getInfoByCarFrameNo(data) {
    return request({
        url: `/api/manage/customizedBill/getInfoByCarFrameNo`,
        method: 'post',
        data
    })
}

// 新增定制账单
export function addCustomizedBill(data) {
    return request({
        url: `/api/manage/customizedBill/addCustomizedBill`,
        method: 'post',
        data
    })
}

// 修改定制账单
export function updateCustomizedBill(data, id) {
    return request({
        url: `/api/manage/customizedBill/updateCustomizedBill/${id}`,
        method: 'put',
        data
    })
}

// 删除定制账单
export function deleteCustomizedBill(id) {
    return request({
        url: `/api/manage/customizedBill/deleteCustomizedBill/${id}`,
        method: 'delete'
    })
}