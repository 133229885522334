import { request } from '@/utils/request'

// 获取全部账单记录
export function getBillList(data, pagenum, size) {
    return request({
        url: `/api/manage/bill/areaList/${pagenum}/${size}`,
        method: 'post',
        data
    })
}

//  导出Gps设备表格数据
export function exportBillExcel(data) {
    return request({
        url: `/api/manage/bill/exportExcel`,
        method: 'post',
        data,
        responseType: 'blob',//服务器返回的数据类型
        timeout: 500 * 1000,
    })
}